import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Layout from '../components/Layout';
import Title from '../components/Title';
import ContactForm from '../components/ContactForm';

const ContactPage = props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { path: { eq: "/contact" } }) {
          html
        }
      }
    `}
    render={data => {
      const { html } = data.markdownRemark;
      return (
        <section id="contact" className="section--markdown">
          <Title title="Contact" />
          <h2>Contact</h2>
          <div
            className="contact__details"
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </section>
      );
    }}
  />
);

export default ContactPage;
